/* Google fonts & font family
==================================================*/

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* General
==================================================*/

.App {
  font-family: "Source Sans 3", sans-serif;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 200px;

  background: #ffffff;
}

/* typography
==================================================*/

h5 {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 5px 0;
}

h6 {
  text-decoration: none;
  font-weight: 400;
  color: rgba(57, 57, 57, 0.8);
  margin: 0 0 15px 10px;
  font-size: 17px;
}

/* Hyperlinks
==================================================*/

/* unvisited link */
a:link {
  text-decoration: none;
  color: inherit;
}

/* visited link */
a:visited {
  text-decoration: none;
  color: inherit;
}

/* mouse over link */
a:hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 3px solid #00a3ff !important;
  color: inherit;
  transition: background 1s cubic-bezier(0.33, 0.66, 0.66, 1);
}

/* selected link */
a:active {
  color: inherit;
  text-decoration: none;
}

/* Elements
==================================================*/

.Nav {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.Button {
  /* Button */

  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;

  /* identical to box height */

  text-transform: uppercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

.Name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

.NameTitle {
  width: 375px;

  font-family: "Oswald";
  font-style: normal;
  font-weight: 450;
  font-size: 70px;
  line-height: 105%;
  gap: 5px;

  text-transform: uppercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

.NameTitleName {
  color: #000000;
}

.NameTitleName:hover {
  color: #00a3ff;
  transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.NameTitleRole {
  color: #00a3ff;
}

.NameTitleRole:hover {
  color: #000000;
}

.NameSubtitle {
  width: 340px;

  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 30px;
  text-transform: lowercase;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

.Profile {
  content: "";
  display: table;
  align-items: left;
  width: 100%;
  gap: 60px;

  /* Inside auto layout */

  /*flex: none;
  align-self: stretch;
  flex-grow: 0;*/
}

.ProfilePic {
  width: 375px;

  padding: 0px 60px 60px 0px;

  float: left;

  /* Inside auto layout */

  /*flex: none;
  flex-grow: 0; */
}

.ProfileInfo {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  width: 60vw;

  float: left;

  color: #000000;
}

.Footer {
}

.footerText {
  font-family: "Source Sans 3";
  position: absolute;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  width: 90vw;
  text-align: center;
  padding: 200px 0px 50px;

  color: #9a9a9a;
}

/* Responsive media queries
==================================================*/

@media (max-width: 600px) {
  .NameTitle {
    width: 90vw;
  }

  .ProfilePic {
    width: 90vw;
  }

  .App {
    padding: 15px;
  }

  .ProfileInfo {
    width: 90vw;
  }
  .ProfilePic {
    padding: 0px 0px 60px 0px;
  }
}
